import {
  formatPercentageValue,
  createKpiTileItem,
} from '../../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { adoptionRatePercentage, adoptionRatePercentageDelta } =
      data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Adoption Rate',
      formatPercentageValue(adoptionRatePercentage),
      adoptionRatePercentageDelta !== undefined
        ? {
            isPositiveValue: adoptionRatePercentageDelta >= 0,
            text: formatPercentageValue(Math.abs(adoptionRatePercentageDelta)),
            sideLabel: '',
            showArrow: true,
          }
        : null
    );
  },
};
